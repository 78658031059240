<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center"></div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div v-if="!is_2fa_enabled" class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center mb-5" style="margin-bottom: 5px">
                <h1 class="">{{ appConfig.config_manager_app_name }}</h1>
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                  alternative
                  class="mb-3"
                  name="Email"
                  prepend-icon="ni ni-email-83"
                  :placeholder="$t('COMMON.EMAIL')"
                  v-model="email"
                />
                <validation-error :errors="apiValidationErrors.email" />

                <base-input
                  alternative
                  class="mb-3"
                  name="Password"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  :placeholder="$t('COMMON.PASSWORD')"
                  v-model="password"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <div v-if="error">
                  <base-alert
                    dismissible
                    type="danger"
                    icon="fa fa-exclamation"
                  >
                    {{ error }}
                  </base-alert>
                </div>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4">
                    {{ $t("COMMON.SIGN_IN") }}
                  </base-button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <a href="/password/reset" class="text-light">
                <small>{{ $t("COMMON.FORGOT_PASSWORD") }}</small>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="is_2fa_enabled" class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center my-5" style="margin-bottom: 5px">
                <h1 class="">{{ appConfig.config_manager_app_name }}</h1>
              </div>
              <form
                role="form"
                @submit.prevent="handleSubmitCodeVerification()"
              >
                <base-input
                  alternative
                  class="mb-3"
                  name="Password"
                  prepend-icon="fas fa-key"
                  type="text"
                  :placeholder="$t('COMMON.VERIFICATION_CODE')"
                  v-model="code"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.code" />

                <div v-if="error">
                  <base-alert
                    dismissible
                    type="danger"
                    icon="fa fa-exclamation"
                  >
                    {{ error }}
                  </base-alert>
                </div>
                <div class="row mt-1">
                  <div class="col-6">
                    <span
                      @click="handleSubmit"
                      style="cursor: pointer"
                      class="text-gray-dark"
                    >
                      <small>{{ $t("COMMON.RESEND_CODE") }}</small>
                    </span>
                  </div>
                </div>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4">
                    {{ $t("COMMON.SIGN_IN") }}
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    error: null,
    code: null,
    is_2fa_enabled: false,
    email: "admin@wise.localhost",
    password: "tN5oI5tA7nG6wT9p",
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Locale: this.$i18n.locale,
        },
      };

      try {
        const response = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });
        if (!!response?.success) {
          this.is_2fa_enabled = !!response?.success;
          this.$notify({
            type: "info",
            //position: 'bottom-right',
            message: response?.message,
          });
        }
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0].detail;
        this.$notify({
          type: "danger",
          message: error?.response?.data?.errors?.[0].detail,
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },

    async handleSubmitCodeVerification() {
      this.error = null;
      const data = {
        data: {
          type: "users",
          attributes: {
            code: this.code,
          },
        },
      };

      try {
        await this.$store.dispatch("users/code_verification", data);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.SUCCESS_VERIFICATION"),
        });
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0]?.detail;
        this.$notify({
          type: "danger",
          message: this.$t("COMMON.ERROR_VERIFICATION"),
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },
  },
};
</script>
